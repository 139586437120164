import React, { Component,useState,useEffect } from "react";
import { Container } from "./styles";
import emailIcon from "../../assets/email-icon.svg";
// import phoneIcon from "../../assets/phone-icon.svg"
// import userIcon from "../../assets/user-icon.svg"
// import locationIcon from "../../assets/location.svg"
import AForm from "../AForm/AForm";
import SForm from "../SForm/SForm";
import { useAppContext } from './../../Context/AppContext'; // Import the custom hook to use the context

import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  message
} from 'antd';
import axios from 'axios';

const { TextArea } = Input;
const { Option } = Select;


const Contact: React.FC = () => {


  const { value }  = useAppContext();
  const [enquiry, setEnquiry] = useState("Education Consultancy");

const formSwitch = (item:any) =>{
  setEnquiry(item);
}


// useEffect(() => {

  
//   if(value){
//     setEnquiry(value);
//   }

// }, [value]);




  return(
    <Container id="contact">
      <header>
        <h2>Contact</h2>
        <br/>
        <p style={{fontSize:"25px",lineHeight:"1.5em"}}>Book a free consultation TODAY  <br/>

</p>
        
      </header>
      <div className="contacts">
        <div style={{maxWidth:"38rem"}}>
        <a href="mailto:info@levelup-2.com"><img src={emailIcon} alt="Email" /></a> 
          <a href="mailto:info@levelup-2.com">info@levelup-2.com</a>
        </div>

        {/* <div>
        <a href="tel:+65 8189 6257"><img src={phoneIcon} alt="Phone No" /></a>
          <a href="tel:+65 8189 6257">(+91) 65 8189 6257</a>
        </div>  
      </div>
      <div className="contacts">

      <div  style={{maxWidth:"38rem"}}>
      <img src={locationIcon}  />
        <br/>
          <h4 >
            Level 42, Suntec Tower 3<br/>
            8 Temasek Boulevard<br/>
            Singapore 038988
          </h4>
        </div>   */}
        
        
      {/* <div>
        <a ><img src={userIcon}  /></a>
          <a >Liza</a>
        </div>   */}
      </div>
     
        <br/>
        <div className="contactForm">
        <Row justify="center" align="middle" style={{ minHeight: '65vh' }}>
        <Col span={24}>    
        <Form
         
          layout="vertical"
          initialValues={{
            size: "large",
            service_type:enquiry
          }}
          
          size="large"
        >  
<Form.Item
            name="service_type"
            label="What We do?"
            rules={[{ required: true, message: 'Please select an option' }]}
           
          >
            <Select   placeholder="Choose " onChange={formSwitch}>
              <Option value="Education Consultancy">Education Consultancy</Option>
              <Option value="Business Support Services">  Business Support Services</Option>
            </Select>
          </Form.Item>
          </Form>

          {
            enquiry ==="Education Consultancy" ?  <AForm /> : <SForm />
          }
            


      </Col>
      </Row>
      </div>
    </Container>
  )
}

export {Contact}