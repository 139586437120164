import React, { Component,useState,useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Badge,Modal } from "antd";





function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

const educationSlider =[
    {
        title: "Singapore",
        img: `../study/location-2.jpeg`,
        content: <>
       
      
        
         
       <blockquote >   Singapore has a reputation for academic excellence not just in Asia, but across the world. The nation’s education system is considered world-class and boasts academic talent that is both highly advanced and competitive. </blockquote><br/><br/>

          <abbr title="Quick facts:">Quick facts:</abbr><br/>
Ranked as the second-best country to live and work in, according to an HSBC ranking in 2019
Courses are more affordable than in the US or the UK
One of the financial capitals of the world
A crucial benefit of choosing Singapore is its distance from India. Singapore is closer to home. Indians comprise nearly 10% of the country's population. So you will feel like you're close to home.
          
       
      
        </>,
    },
    {
        title: "Malaysia",
        img: `../study/location-1.jpeg`,
        content: <>
        
  

  With natural attractions, rich culture and gorgeous scenery, Malaysia attracts people from all over the world. The country has a unique combination of ancient and modern architecture, inspired by their mix of cultures and ethnicities. Its location makes it the perfect destination for any international student looking for a new experience for their studies and has progressively emerged as one of the prominent student destinations in Asia. Home to many international Universities, it is well-known for providing quality education at inexpensive costs as well as simpler visa and immigration procedures thus attracting students from across the globe.<br/><br/>

  <abbr title="Quick facts:">Quick facts:</abbr><br/>
Malaysia is an increasingly popular place for higher education with international students from over 100 countries studying in Malaysia
Affordable cost of living and cost of education
Home to many foreign Universities
After completion of higher education, employment prospects widen.
 
  <br/>
       
      
        </>,
    },
    {
        title: "Vietnam",
        img: `../study/location-3.jpeg`,
        content: <>
       


Vietnam's growing economy and increasing global integration offer promising opportunities for international students after graduation. Many multinational companies operate in Vietnam, providing job prospects in various sectors such as technology, finance, hospitality, and manufacturing

       
      
        </>,
    },
];


// const businessSlider =[
//     {
//         title: "Book keeping",
//         img: `../services/service-5.png`,
       
//     },
//     {
//         title: "Payroll",
//         img: `../services/service-6.png`,
//     },
//     {
//         title: "Accounting",
//         img: `../services/service-7.png`,
//     },
// ];



const businessSlider = [

  {
    title:"Management Consultancy",
    child: ["Business Strategy Consultation","Change Management"],
    img:`../services/b-service-01.png`,
    is_service: true,
  },
  {
    title:"Data Entry Services",
    child: ["Data Entry","Data and Reporting"],
    img:`../services/b-service-02.png`,
    is_service: true,
  },
  {
    title:"Accounting & Admin Services",
    child: ["AP / AR Services","Accounting","Bookkeeping","Payroll"],
    img:`../services/b-service-03.png`,
    is_service: true,
  },
  {
    title:"Sales & Marketing",
    child: ["Email Marketing","Customer Support","Inbound Sales Support"],
    img:`../services/b-service-04.png`,
    is_service: true,
  },
  {
    title:"Operations",
    child: ["Phone Support Service","Live Chat Service","Operations Assistant","Help Desk","Virtual Assistant"],
    img:`../services/b-service-05.png`,
    is_service: true,
  }
];


function DesSlider(props:any ) {

  const [content, setContent] = useState(false);
  const [isMobile, setIsLargerThan425] = useState(window.innerWidth > 768);

  const handleResize = () => {
    setIsLargerThan425(window.innerWidth > 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Check the initial width when the component mounts
    handleResize();

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


    const sliderData =  (props.sliderType =="business" ? businessSlider  : educationSlider )
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: (isMobile ? 3 :  1),
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const getPoup = (item :any) =>{


    // if(item.content ) {
        console.log(item);
        setContent(item);
    // }

  }

  const closeEvent = () =>{
    setContent(false);
  }



  return (
    <div className="slider-container">
      <Slider {...settings}>
{sliderData.map((item,index)=> 
        <div key={index}>
          <Badge.Ribbon
            color="#c9cad7"
            placement="start"
            text={<> &nbsp;{item.title}&nbsp;</>}
          >
            <img  onClick={() => getPoup(item)}  className="slider_img" src={item.img} />
          </Badge.Ribbon>
        </div>)}

       
        
      </Slider>
      {content ? <PopUp  onClose={closeEvent}  data={content} /> : "" }
      
    </div>
  );
}


// Model Poppup


function PopUp(props:any) {
  const [isModalOpen, setIsModalOpen] = useState(true);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    props.onClose();
  };

console.log(props);

  return (
    <>
      
      <Modal footer={null} width={"650px"} title={<h1>{props.data.title}</h1>} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>

          {props.data.is_service ? <>

            <ul className="child_ul_li">
      {props.data.child.map((child:any)=>
        <li>
          {child}
        </li> )}
        
      </ul>
          </>   :
        <p style={{fontSize: "18px"}}>     {props.data.content}</p>}
        
      </Modal>
    </>
  );
};
// 



export default DesSlider;
