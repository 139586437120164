import styled from "styled-components";


export const Container = styled.main`
  header{
      text-align: center;
      h2{
        text-align: center;
        font-size: 4rem;
      }
      p{
        color: var(--green);
        font-weight: 500;
      }
    }

   

   
  position: relative;
  z-index: 0;
  padding: 0 10rem;
  overflow-x: hidden;
  #tsparticles{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  @media (max-width: 740px){
    padding: 0 4rem;
  }

  @media(max-width: 360px){
    padding: 0 2rem;
  }

  form {
    background: #fff;
  

    button {
      box-shadow: 0 0 5px;
      width: 25%;
      
       &:hover {
         background-color: var(--blue) !important;
       }
       
    }
    
    
  
  
`