import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root{
    --pink: #151B54;
    --black:#fff;
    --green: #151B54;
    --blue: #151B54;
    --text: #000;
    scroll-padding-top: 10rem;

    
    @font-face {
      font-family: 'Calibri';
      src: url('./../font/calibri-regular.ttf');
      font-weight: normal;
      font-style: normal;
    }

  .child_ul_li  {
          li {
               font-size: 22px;
              color: #ccc;
              list-style: circle;
              margin: 0 20px;
              color: var(--blue);
          }
      }
    .about-text {
      color : var(--text);
    }

    h2,h3,.ant-modal-title{
      color : var(--blue);
    }
    &.light{

      body{
        transition: 0.5s;
        background-color: #f5f5f5;
        color: var(--black);
      }

      .logo{
        color: var(--black);
      }

      header.header-fixed{
        transition: 0.5s;
        background-color: #f5f5f550;
        a{
          transition: 0.5s;
          color: black;
        }
        .menu,.menu:before, .menu:after{
          background-color: var(--black); 
        }
        .menu.active{
          background-color: rgba(555,555,555,0);
        }
      }

      footer.footer{
        transition: 0.5s;
        background-color: rgba(0,0,0,0.1);
        color: var(--black);
      }

      form{
        input,textarea{
          transition: 0.5s;
          border: solid 1px var(--black);
          color: var(--black);
          &::placeholder{
            transition: 0.5s;
            color: var(--black);
          }
        }
      }

    }
  }

  ul, li {
    text-decoration: none;
    list-style: none;
    margin: 0;
    padding:0;
  }

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Calibri';
  }

  html{
    font-size: 62.5%;
  }

  body{
    font-size: 1.6rem;
    -webkit-font-smoothing: antialiased;
    background-color: var(--black);
    color: #FFFF;
  }

  body, input, textarea, button{
   
    // font-family: 'Red Hat Display', sans-serif;
    font-weight: 400;
  }

  a{
    text-decoration: none;
  }

  button, .button{
    border: none;
    cursor: pointer;
    background-color: var(--blue);
    color: #fff;
    border-radius: 2rem;
    font-weight: 500;
    transition: filter 0.25s;
    &:hover{
      filter: brightness(0.8);
      background-color: var(--blue) !important;
    }
  }

  button:disabled, .button:disabled{
    filter: brightness(0.8);
    cursor: not-allowed;
  }


  .logo{
    font-size: 3rem;
    color: #FFFF;
    // &::first-letter{
    //   color: var(--green);
    // }
  }

 .slider_dot { 
    ul.slick-dots.slick-dots-bottom li {
      background: var(--blue);
    }
 }

 .top-header{
    background: var(--blue);
    padding: 15px;
    font-size: 25px;
    text-align: right;
    span{
      position:relative;
      right:50px;
    }
 }

 .ant-dropdown-trigger {
  cursor:pointer;
 }
 .ant-dropdown a  {
    font-size: 25px !important;
    color: var(--blue) !important;
    cursor:pointer;
  }

  .edu-images {
            padding:30px !important;
          }
     @media(max-width: 425px) {
         .top-header {
            display: none;
        }


        h1 {
         
          font-size: 15px !important;  
            i {
              font-size: 10px !important;  
            }
        }

        button, .button {
           width:80% !important;
           font-size: 12px !important;  
           padding: 0px !important;
        }

        .slider_img {
            width: 100%;
            height: auto !important;
            object-fit: contain !important;
        }

        .ant-result {
            padding: 0px !important;
        }

       
          #slider {
              padding-top: 30% !important;
            }

         main {
         padding: 0 1.5rem !important;
         }
         

         .about-text {
         
            h1 {
              font-size:25px !important;
            }
            
            p {
              text-align :start;
              font-size: 18px;
            }
         }
        .about-image {

            margin-top: 0px !important;
            margin-bottom: 15px !important;

          .edu-images {
            padding:0px !important;
          }
            img {
                  height: 140px!important;
                  object-fit: fill !important;
            }
        }

         .insideText {
            left:0% !important;
         }


         .study_slider .slick-slide img {
               height: 230px !IMPORTANT;
              object-fit: cover !important;
         }

         .study_slider .ant-ribbon.ant-ribbon-placement-start {
            
          .ant-ribbon-text {
              font-size: 20px;
            }
              margin: 160px 0px !important;
             padding: 0px !important;
         }
            

        
         .study_slider .slider-container .slick-slide div {
            padding:0px !important;
         }

         .ant-modal-title {
            h1 {
              font-size:25px !important;
            }
         }
            .ant-modal .ant-modal-close {
                left: 200px;
                }


                .contactForm {
                  margin: 0 !important;    
                  padding: 15px 15px !important;

                  button{
                    font-size: 21px !important;
                  }
                }
     }
`