import { Carousel, Row, Col, Result, Button } from "antd";
import { BrowserRouter } from "react-router-dom"
import { NavHashLink } from "react-router-hash-link"
import React from "react";
import { Container } from "./styles";

import { useAppContext } from './../../Context/AppContext'; 


const contentStyle: React.CSSProperties = {
  height: "350px",
  color: "#fff",
  lineHeight: "350px",
  textAlign: "center",
  background: "#364d79",
};

const settings = {
  dots: false,
  dotsClass: "slick-dots slick-thumb",
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
 
};


  const Slider: React.FC = () => {


  const { handleClick } = useAppContext();

const contactClick = (value :any) =>{
    handleClick(value);
}

  return (
    <Container id="slider">
      <div className="hero-text" id="about">
        <Carousel   {...settings} customPaging={i=> <button>next {i + 1}</button>} autoplaySpeed={3500} speed={500} swipe={true} touchMove={true} pauseOnHover={true} draggable={true} autoplay>
          
          <div>
            <Row gutter={[8, 8]}>
              <Col span={8}>
                <Result
                  icon={<></>}
                  title={<h1>Business Support Services</h1>}
                  extra={  <BrowserRouter>
                    <NavHashLink  smooth to="#business" className="button"><Button onClick={()=> contactClick("Business Support Services")} type="primary">Learn More</Button></NavHashLink>
                  </BrowserRouter>}
                />
              </Col>

              <Col span={14}>
                {" "}
                <img
                  className="slider_img"
                  src={`../slider/slider04.webp`}
                />{" "}
              </Col>
            </Row>
          </div>
          <div>
            <Row gutter={[8, 8]}>
              <Col   span={12}>
                <Result
                  icon={<></>}
                  title={<h1 ><i>Want to</i> Study Abroad? </h1>}
                  extra={  <BrowserRouter>
                    <NavHashLink  smooth to="#contact"  className="button"><Button onClick={()=> contactClick("Education Consultancy")} type="primary">Book a FREE Consultation</Button></NavHashLink>
                  </BrowserRouter>}
                />
              </Col>
            
              <Col span={12}>
                {" "}
                <img
                  className="slider_img"
                  src={`../slider/study-2.jpg`}
                />{" "}
              </Col>
            </Row>
          </div>
        </Carousel>
        
      </div>
    </Container>
  );
};

export  {Slider};
