import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';
import React,{useState} from 'react';

import { BrowserRouter as Router } from 'react-router-dom'
import { NavHashLink, HashLink } from 'react-router-hash-link'
import { HdContent } from "./styles";


{/* <NavHashLink smooth to="#project" onClick={closeMenu}>
            What we do
          </NavHashLink> */}
  
export function HDropDown(props:any) {   

   
    function closeMenu() {
      props.childClick(false)
    }

    
    return (
        <HdContent>
  <Dropdown  placement="bottom"
          overlay={<Menu>
    <Menu.Item>
    <NavHashLink smooth to="#education" onClick={closeMenu}>
     
      Education Consultancy
      
      </NavHashLink>
    </Menu.Item>
    <Menu.Item>
    <NavHashLink smooth to="#business" onClick={closeMenu}>
    
      Business Support Services
      
      </NavHashLink>
    </Menu.Item>
    
  </Menu>}>
    <a onClick={e => e.preventDefault()}>
      <Space>
      What we do
        <DownOutlined />
      </Space>
    </a>
  </Dropdown>
  </HdContent>
)
};

